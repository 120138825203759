$appbar-height: 48px;
$drawer-width: 240px;

// Colors
$color-primary: #23b5d3;
$color-primary-light: lighten($color-primary, 20%);
$color-primary-dark: darken($color-primary, 20%);

$color-info: #006987;
$color-info-light: lighten($color-info, 20%);
$color-info-dark: darken($color-info, 20%);
